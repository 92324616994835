export const environment = {
  production: true,
  development: false,
  GA_TRACKER_ID: 'UA-140814636-2',
};

export const API_URL = 'https://api.statoutdoors.com';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBzsz36voRor52FpzEvUL00Bg8mogKBihc';

export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51J7tiqGWJyYGy2XwFOrRmMF1fOtD7IQQT8zoVdalGwBqUoXsodyOwL9yi1kB1uZmluixZIhYVogZPqfuiqDe8a8y00ZU6QuS8n'
export const BUGSNAG_API_KEY = '563c50d05d41e66749b675a933721dda'
export const GLASSFY_API_KEY = '605ea6cf33dd44eb8ea51c3e0822a312'
